import { Container } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const InterviewDanielCaro = () => {
  return (
    <Layout>
      <StaticImage
        src={"../assets/images/interviews/interview-traeume.jpg"}
        alt="Detharding Interview - Träume"
        placeholder="blurred"
        layout="fullWidth"
        quality={100}
      />
      <Container>
        <h1 className="gold-header d-flex justify-content-center pb-3">
          Wenn Träume in Erfüllung gehen ...
        </h1>
        <p>
          Als Christiane Detharding das Haus von Caro und Daniel zum ersten Mal
          sah, war sie auf den ersten Blick begeistert: Denn sie hatten den
          Altbau aus den 1930er-Jahren mit viel Liebe zum Detail in eine
          stilvolle Wohlfühloase mit einem außergewöhnlichen Raumkonzept
          verwandelt – sogar einen Schwimmteich hatte Daniel selbst angelegt.
          Dass Christiane dieses Schmuckstück einmal für die beiden verkaufen
          würde, wusste anfangs aber noch keiner der drei. Doch der Reihe nach
          ...
        </p>
        <p>
          „Ich war mit einer Freundin unterwegs am Kasseler Jungfernkopf“,
          erzählt Christiane. „Sie sagte: ,Da hat jemand aus einem fast hundert
          Jahre alten Haus eine ganz coole Nummer gemacht, mit Badeteich und
          allem Drum und Dran. Musst du dir unbedingt mal angucken!‘ Wir sind
          dann einfach spontan vorbeigefahren!“
        </p>
        <p>
          Und Caro erinnert sich: „Wir trafen die beiden draußen und auch Daniel
          war auf den ersten Blick begeistert – von Christianes Land Rover! Er
          sagte zu ihr: ,Schönes Auto! Wenn du es mal verkaufen willst, ruf mich
          an!‘ – worauf sich ein nettes Gespräch ergab, in dem wir auch
          erfuhren, dass Christiane Maklerin ist.“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Wenn einer eine Reise plant ...
        </h1>
        <p>
          Zu diesem Zeitpunkt hätten Caro und Daniel allerdings noch nicht mal
          im Traum daran gedacht, dass sie die Dethardings schon ein paar Wochen
          später mit dem Verkauf ihres Hauses beauftragen würden. Doch dann kam
          der Traum von einer ausgedehnten Weltreise: „Wir hatten schon öfter
          darüber gesprochen, um die Welt zu reisen“, berichtet Daniel. „Aber so
          ein Vorhaben kostet ja schließlich Geld. Irgendwann im Sommer saßen
          wir auf dem Balkon, haben lange überlegt und dann tatsächlich
          beschlossen, dafür unser Haus zu verkaufen!“
        </p>
        <p>
          Die beiden waren sich einig, dass sie hierfür nicht einen x-beliebigen
          Immobilienmakler beauftragen wollten. Da fiel ihnen die nette
          Begegnung mit Christiane wieder ein. „Wir haben uns ihre Nummer
          besorgt und angerufen“, erzählt Caro. „Ein paar Tage später kam sie
          dann mit Philip vorbei und wir waren uns schnell darüber einig, dass
          die beiden das für uns übernehmen sollen!“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Der erste Eindruck zählt
        </h1>
        <p>
          Christiane und Philip machten sich an die Arbeit und standen schon
          kurz nach dem ersten Termin mit der Kamera vor der Tür: Zeit fürs
          Fotoshooting! „Ich hatte extra gründlich aufgeräumt“, schmunzelt Caro.
          „Aber Christiane hat noch mal alles umgekrempelt und ein perfektes
          Ambiente kreiert.“ Insgesamt dauerte das Shooting dann ein paar
          Stunden. „Doch das hat sich gelohnt“, unterstreicht Caro. „Denn die
          Bilder sind einfach super geworden!“ Und Christiane ergänzt: „Wir
          nehmen uns für die Fotos und auch für das Home Staging immer richtig
          viel Zeit. Denn nur wenn der visuelle Eindruck sofort überzeugt, kommt
          das Angebot beim Interessenten in die engere Wahl!“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Liebe auf den ersten Blick
        </h1>
        <p>
          Das können Nadja und Boris nur bestätigen: Boris hatte das Haus über
          ein Immobilienportal im Internet entdeckt und war sofort Feuer und
          Flamme. „Ich war gerade in Dänemark“, erzählt er. „Als ich die Anzeige
          entdeckt hatte, schrieb ich sofort Nadja, die dann gleich einen
          Besichtigungstermin vereinbart hat.“
        </p>
        <p>
          „Wir gehörten zu den Ersten, die sich das Haus anschauen konnten“,
          berichtet Nadja. „Weil Boris noch verreist war, habe ich einen Freund
          von uns mitgenommen. Wir fanden’s beide richtig klasse. Noch am selben
          Abend habe ich dann mit Boris entschieden, dass wir das Haus nehmen
          wollen. Christiane war völlig überrascht über diese Blitzentscheidung:
          ,Aber Ihr Mann hat es doch noch gar nicht gesehen!‘ – ,Kein Problem –
          wir nehmen es‘, habe ich gesagt.“ Und sie fügt lachend hinzu:
          „Hinterher hat Philip mir erzählt, dass Dethardings es früher schon
          mal exakt genauso gemacht haben – ohne es je zu bereuen!“
        </p>
        <br />
      </Container>
      <StaticImage
        src={"../assets/images/interviews/interview-kaffeeduft.jpg"}
        alt="Detharding Interview - Kaffeeduft"
        placeholder="blurred"
        layout="fullWidth"
        quality={100}
      />
      <Container className="pb-5 mb-5">
        <h1 className="gold-header d-flex justify-content-center py-3">
          Kaffeeduft und tolle Musik
        </h1>
        <p>
          Einen gemeinsamen Besichtigungstermin gab es dann aber doch noch – und
          der war für Nadja und Boris unvergesslich: „Als wir zum Haus kamen,
          wurden wir von toller Musik und Kaffeeduft empfangen“, erinnert sich
          Boris. „Es war Christiane, die uns lachend empfing und erst mal einen
          Espresso angeboten hat!“ Christiane lacht: „Ich habe bei
          Besichtigungsterminen eigentlich immer meine Espressomaschine dabei.
          Ist doch schön, wenn sich die Interessenten gleich wie zu Hause
          fühlen, wenn Sie eine Immobilie anschauen!“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Viel Zeit und hundertprozentige Transparenz
        </h1>
        <p>
          Ein rundum ungewohntes Besichtigungserlebnis, das auch Nadja und Boris
          so noch nicht kannten: „Meist trifft man den Makler vor dem Haus. Der
          schließt einem die Tür auf und hat die Immobilie vielleicht selber
          noch nicht gesehen. Christiane dagegen war topinformiert und hat sich
          richtig Zeit genommen, um uns alles bis ins Detail zu zeigen.“ Und
          noch etwas ist ihnen positiv aufgefallen: „Christiane und Philip sind
          immer hundertprozentig ehrlich und transparent. Dazu gehört auch, dass
          sie einen auch auf mögliche Makel hinweisen oder wo noch kleinere
          Baustellen auf die Käufer warten. Besser geht’s eigentlich nicht!“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Vertrauen und ein Gefühl von Sicherheit
        </h1>
        <p>
          Auch Caro und Daniel fühlten sich bei Christiane und Philip von Anfang
          an gut aufgehoben: „Wir haben uns unheimlich gut unterstützt gefühlt“,
          bestätigt Caro. „Für uns war das der erste Hausverkauf, da hat man
          natürlich jede Menge Fragen, die Christiane alle geduldig beantwortet
          hat. Wir konnten jederzeit anrufen, wenn wir etwas auf dem Herzen
          hatten!“ Und Daniel ergänzt: „Bei einem Hausverkauf geht es ja
          letztlich auch um viel Geld – das sind schon mal existenzielle Summen.
          Da jemanden zu haben, dem man absolut vertrauen kann, ist wichtig.“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Ein fröhlicher Vertragsabschluss
        </h1>
        <p>
          „Etwas anders als gewohnt war dann auch der Notartermin“, erzählt
          Christiane: „Der Notar sagte anschließend, so was hätte er noch nicht
          erlebt – dass Käufer, Verkäufer und Makler gemeinsam eine Flasche Sekt
          köpfen und miteinander anstoßen, und das auch noch vormittags!“ –
          „Irgendwann sagte er dann: ‚Sie müssen jetzt langsam mal gehen‘“,
          ergänzt Caro lachend.
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Der Sprung ins kalte Wasser
        </h1>
        <p>
          Übrigens hat auch Christiane schon das erfrischende Nass im
          Schwimmteich des Hauses genossen, das inzwischen Nadja und Boris
          gehört: Beim Dreh eines Instagramvideos ist sie mit voller Montur ins
          Wasser gefallen. Und das war zu diesem Zeitpunkt noch ganz schön kalt,
          wie Caro berichtet: „Das Video wurde morgens um halb acht gedreht und
          da war es noch richtig frisch!“
        </p>
        <p>
          Zu sehen gibt’s das Video mitsamt einiger schöner Bilder vom Haus
          hier:
          <br />
          <a
            href="https://www.instagram.com/detharding.immobilien/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.instagram.com/detharding.immobilien/
          </a>
        </p>
      </Container>
    </Layout>
  );
};

export default InterviewDanielCaro;
